import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { email as emailRegex } from 'magic-tricks/lib/regex';
import xss from 'xss';
import cookie from 'js-cookie';

import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

const TRANSITION_DURATION = 400;
const OPEN_DELAY = 2000;

const BACKGROUND_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease`,
		opacity: 0,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const MODAL_TRANSITION_STYLES = {
	default: {
		transition: `transform ${TRANSITION_DURATION}ms cubic-bezier(0, 0.25, 0, 1), opacity ${TRANSITION_DURATION}ms ease`,
		transform: 'translate3d(0, 30px, 0)',
		opacity: 0,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
		transform: 'translateY(0)',
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const NewsletterPopup = ({ type, title, newsletterCopy }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [state, setState] = useState({
		consent: false,
		email: null,
		isSuccess: false,
		isError: false,
		isSubmitting: false,
		showInvalidEmailError: false,
	});
	const NEWSLETTER_KEY = `library-street-collective_newsletter--${title.toLowerCase()}`;
	const hasSeenNewsletter = cookie.get(NEWSLETTER_KEY);

	useEffect(() => {
		if (!hasSeenNewsletter) {
			setTimeout(() => {
				setIsOpen(true);
			}, OPEN_DELAY);
		}
	}, [hasSeenNewsletter]);

	const handleModalClick = e => {
		const { currentTarget, target } = e;

		if (currentTarget !== target) return;

		setIsOpen(false);
		cookie.set(NEWSLETTER_KEY, true, { expires: 7 });
	};

	const onChangeEmail = event => {
		const { showInvalidEmailError } = state;
		setState({
			...state,
			email: xss(event.target.value),
			showInvalidEmailError: showInvalidEmailError
				? !emailRegex.test(event.target.value)
				: showInvalidEmailError,
		});
	};

	const handleSubmit = async event => {
		event.preventDefault();

		if (!state.consent) {
			setState({
				...state,
				showInvalidEmailError: false,
				isSubmitting: false,
				isError: 'Please accept the terms & conditions below',
			});
			return;
		}

		if (!state.email) {
			setState({
				...state,
				showInvalidEmailError: false,
				isSubmitting: false,
				isError: 'Please enter your email.',
			});
			return;
		}

		if (!emailRegex.test(state.email)) {
			setState({
				...state,
				showInvalidEmailError: true,
				isError: false,
				isSubmitting: false,
			});

			return;
		}

		setState({
			...state,
			isSubmitting: true,
			isError: false,
			showInvalidEmailError: false,
		});

		fetch('/.netlify/functions/email', {
			method: 'POST',
			body: JSON.stringify({
				email: state.email,
			}),
		})
			.then(res => {
				return res.json();
			})
			.then(response => {
				if (response.success) {
					setState({
						...state,
						isError: null,
						isSuccess: 'Thank you for signing up!',
					});

					setTimeout(() => {
						setIsOpen(false);
						cookie.set(NEWSLETTER_KEY, true, { expires: 7 });
					}, OPEN_DELAY);
				}
			})
			.catch(error => {
				console.error(error);
				setState({
					...state,
					isError:
						'Uh oh. Something went wrong. Please try again later.',
				});
			});
	};

	return (
		<Transition
			in={isOpen}
			mountOnEnter
			unmountOnExit
			appear
			timeout={TRANSITION_DURATION}
		>
			{status => (
				<div
					className="pf fill newsletter-modal__background z10 df fdc jcc aic p2"
					style={{
						...BACKGROUND_TRANSITION_STYLES.default,
						opacity: 0,
						...BACKGROUND_TRANSITION_STYLES[status],
					}}
					onClick={handleModalClick}
				>
					<aside
						className="bg--white newsletter-modal__content p2 p4--lg"
						style={{
							...MODAL_TRANSITION_STYLES.default,
							opacity: 0,
							...MODAL_TRANSITION_STYLES[status],
						}}
					>
						<p className=" mt2">
							Please sign up for our newsletter to view {type}
							{': '}
							{title}
						</p>

						<form
							onSubmit={handleSubmit}
							className="mt2 newsletter-modal__form"
						>
							<div className="pr df aic">
								<label className="db x newsletter-modal__form-label">
									<input
										type="email"
										name="email"
										placeholder="Email Address*"
										className="db x newsletter-modal__form-input pl1 pr1"
										required
										onChange={onChangeEmail}
									/>
								</label>

								<button
									className="newsletter-modal__form-submit pa right mr1"
									disabled={!state.consent}
								>
									Submit
								</button>
							</div>
							{state.isError && (
								<p className=" --sm error mt1 mt2--md ">
									{state.isError}
								</p>
							)}
							{state.showInvalidEmailError && (
								<p className="mt1  --sm error mt2--md">
									Please fix your email address.
								</p>
							)}
							{state.isSuccess && (
								<p className=" --sm mt1 mt2--md ">
									{state.isSuccess}
								</p>
							)}
							<div className="mt1  df aic pt2">
								<label className="newsletter__email-consent mr1">
									<input
										type="checkbox"
										name="email-consent"
										onChange={e =>
											setState({
												...state,
												consent:
													e.currentTarget.checked,
											})
										}
									/>
								</label>
								{newsletterCopy && (
									<div className="rich-text sans--14 col c8">
										<BlockContent
											blocks={newsletterCopy}
											serializers={richText}
										/>
									</div>
								)}
							</div>
						</form>
					</aside>
				</div>
			)}
		</Transition>
	);
};
export default NewsletterPopup;
