import React, { Fragment, useContext } from 'react';
import SEO from '../components/seo';
import Reveal from '../components/Reveal';
import ComponentList from '../components/ComponentList';
import PullQuote from '../components/PullQuote';
import NewsletterPopup from '../components/NewsletterPopup';
import ArrowRight from '../components/icons/ArrowRight';
import Context from '../context/Context';

import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import sanityImage from '../util/sanityImage';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import useHover from '../util/useHover';
import VideoPlayer from '../components/VideoPlayer';

const Anatomy = ({ pageContext, location }) => {
	const [hoverRef, isHovered] = useHover();
	const [sliderRef, slider] = useKeenSlider({
		slidesPerView: 1,
		loop: true,
		spacing: 50,
	});

	const {
		seo = {},
		title,
		hero = {},
		heroGallery = [],
		subtitle,
		intro,
		backgroundTitle,
		backgroundImage = {},
		backgroundText = [],
		backgroundVideo,
		backgroundGallery = [],
		inspirationTitle,
		inspirationCover = {},
		inpirationSection = {}, // misseplled
		inspirationQuote = {},
		inspirationGallery = [],
		artworksTitle,
		artworkSectionDescription,
		artworkSectionVideo,
		artworkSections = [],
		conversationsTitle,
		conversationsCover = {},
		conversationSections = [],
		studioVisit = [],
		inquire,
		footer,
	} = pageContext;

	const metaTitle = seo?.metaTitle || 'Anatomy | Library Street Collective';
	const openGraphTitle =
		seo?.openGraphTitle || 'Anatomy | Library Street Collective';
	const twitterTitle =
		seo?.twitterTitle || 'Anatomy | Library Street Collective';

	const { setLightBoxOpen, setFeaturedImage } = useContext(Context);

	const heroSection = (heroGallery || hero) && (
		<Reveal>
			<section className="reveal__slide reveal__delay--1">
				{heroGallery && heroGallery.length > 0 ? (
					<div className="grid-container contained mt5 mt10--md pr">
						<div
							ref={sliderRef}
							className="project__carousel keen-slider df pr"
						>
							{heroGallery.map(image => (
								<picture
									key={image._key}
									className="keen-slider__slide"
								>
									<source
										srcSet={sanityImage(image.url, {
											w: 2400,
										})}
										media="(min-width: 1800px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 2000,
										})}
										media="(min-width: 1600px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1600,
										})}
										media="(min-width: 1200px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1200,
										})}
										media="(min-width: 800px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1000,
										})}
										media="(min-width: 600px)"
									/>
									<img
										alt={
											image.alt ||
											title ||
											'Library Street Collective'
										}
										className="db x y object-fit--cover b--black"
										src={sanityImage(image.url, {
											w: 800,
										})}
									/>
								</picture>
							))}
						</div>
						{slider && (
							<Fragment>
								<button
									className="slider__button slider__button--prev pa"
									onClick={e =>
										e.stopPropagation() || slider.prev()
									}
								>
									<ArrowRight className="icon icon--vertical icon--slider flip" />
								</button>
								<button
									className="slider__button slider__button--next pa"
									onClick={e =>
										e.stopPropagation() || slider.next()
									}
								>
									<ArrowRight className="icon icon--vertical icon--slider" />
								</button>
							</Fragment>
						)}
					</div>
				) : (
					<picture
						ref={hoverRef}
						className="db x bb--black artwork--full-container"
					>
						<source
							srcSet={sanityImage(hero.url, { w: 2400 })}
							media="(min-width: 1800px)"
						/>
						<source
							srcSet={sanityImage(hero.url, { w: 2000 })}
							media="(min-width: 1600px)"
						/>
						<source
							srcSet={sanityImage(hero.url, { w: 1600 })}
							media="(min-width: 1200px)"
						/>
						<source
							srcSet={sanityImage(hero.url, { w: 1200 })}
							media="(min-width: 800px)"
						/>
						<source
							srcSet={sanityImage(hero.url, { w: 1000 })}
							media="(min-width: 600px)"
						/>
						<img
							alt={
								hero.alt || title || 'Library Street Collective'
							}
							className={cx('db artwork--full', {
								hovered: isHovered,
							})}
							src={sanityImage(hero.url, { w: 800 })}
						/>
					</picture>
				)}
				<aside className="mt2 grid-container contained">
					<h1 className="sans--24 sans--36--md sans--48--lg">
						Anatomy: {title}
					</h1>
					{subtitle && (
						<p className="sans--14 sans--18--md sans--24--lg">
							{subtitle}
						</p>
					)}
				</aside>
			</section>
		</Reveal>
	);

	const introSection = intro && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg grid-container contained">
				<div className="row align--center">
					<div className="col c10--md c9--lg c7--xl">
						<div className="sans--18 sans--24--lg line-break rich-text ">
							<BlockContent
								blocks={intro}
								serializers={richText}
							/>
						</div>
					</div>
				</div>
			</div>
		</Reveal>
	);

	const backGroundSection = backgroundImage && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg ">
				<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md grid-container contained">
					{backgroundTitle || 'Background'}
				</p>

				{backgroundImage.url && (
					<picture>
						<source
							srcSet={`${backgroundImage.url}?w=2000&auto=format&q=100`}
							media="(min-width: 1200px)"
						/>
						<source
							srcSet={`${backgroundImage.url}?w=1200&auto=format&q=100`}
							media="(min-width: 1000px)"
						/>
						<source
							srcSet={`${backgroundImage.url}?w=800&auto=format`}
							media="(min-width: 600px)"
						/>
						<img
							alt={title || 'Library Street Collective'}
							className="db x bt--black bb--black"
							src={`${backgroundImage.url}?w=600&auto=format`}
						/>
					</picture>
				)}
				<section className="grid-container contained">
					<div className="row align--center mt5 mt10--md mb5 mb10--md mb20--lg mt20--lg">
						<div className="col c10--md c9--lg c7--xl">
							<div className="sans--18 sans--24--lg line-break rich-text ">
								<BlockContent
									blocks={backgroundText}
									serializers={richText}
								/>
							</div>
						</div>
					</div>

					{backgroundVideo && <VideoPlayer {...backgroundVideo} />}

					{backgroundGallery && backgroundGallery.length > 0 && (
						<div className="row ">
							{backgroundGallery.map((item, index) => {
								const {
									_key,
									title,
									description,
									artwork = {},
								} = item;

								return (
									<figure
										key={_key}
										className={cx('col c6--md', {
											mt5: index > 0,
											'mt0--md': index === 1,
											'mt10--md mt20--lg': index > 1,
										})}
									>
										<picture>
											<source
												srcSet={`${artwork.url}?w=2000&auto=format&q=100`}
												media="(min-width: 1200px)"
											/>
											<source
												srcSet={`${artwork.url}?w=1200&auto=format&q=100`}
												media="(min-width: 1000px)"
											/>
											<source
												srcSet={`${artwork.url}?w=800&auto=format`}
												media="(min-width: 600px)"
											/>
											<img
												alt={
													title ||
													'Library Street Collective'
												}
												className="db x b--black pointer"
												onClick={() => {
													setFeaturedImage(item);
													setTimeout(() => {
														setLightBoxOpen(true);
													}, 100);
												}}
												src={`${artwork.url}?w=600&auto=format`}
											/>
										</picture>
										{title && (
											<div className="mt2 sans--14 sans--18--lg">
												{title}
											</div>
										)}
										{description && (
											<div className="mt2 sans--14 sans--18--lg">
												<BlockContent
													blocks={description}
													serializers={richText}
												/>
											</div>
										)}
									</figure>
								);
							})}
						</div>
					)}
				</section>
			</div>
		</Reveal>
	);

	const inspirationCoverSection = inspirationCover && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg">
				<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md grid-container contained">
					{inspirationTitle || 'Inspiration'}
				</p>

				{inspirationCover.url && (
					<picture>
						<source
							srcSet={`${inspirationCover.url}?w=2000&auto=format&q=100`}
							media="(min-width: 1200px)"
						/>
						<source
							srcSet={`${inspirationCover.url}?w=1200&auto=format&q=100`}
							media="(min-width: 1000px)"
						/>
						<source
							srcSet={`${inspirationCover.url}?w=800&auto=format`}
							media="(min-width: 600px)"
						/>
						<img
							alt={title || 'Library Street Collective'}
							className="db x bt--black bb--black"
							src={`${inspirationCover.url}?w=600&auto=format`}
						/>
					</picture>
				)}
			</div>
		</Reveal>
	);

	const inspirationSection = inpirationSection && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg grid-container contained">
				<div className="row">
					{inpirationSection?.image && (
						<figure className="col c5--lg psy--lg top pt10--lg">
							<picture>
								<source
									srcSet={`${inpirationSection.image.artwork.url}?w=1200&auto=format&q=100`}
									media="(min-width: 1000px)"
								/>
								<source
									srcSet={`${inpirationSection.image.artwork.url}?w=800&auto=format`}
									media="(min-width: 600px)"
								/>
								<img
									alt={
										inpirationSection.image.title ||
										'Library Street Collective'
									}
									className="db b--black pointer"
									onClick={() => {
										setFeaturedImage(
											inpirationSection.image
										);
										setTimeout(() => {
											setLightBoxOpen(true);
										}, 100);
									}}
									src={`${inpirationSection.image.artwork.url}?w=600&auto=format`}
								/>
							</picture>

							{inpirationSection?.image?.title && (
								<figcaption className="rich-text line-break sans--14 sans--18--lg pt1">
									{inpirationSection?.image?.title && (
										<p>{inpirationSection.image.title}</p>
									)}
									{inpirationSection?.image?.description && (
										<BlockContent
											blocks={
												inpirationSection.image
													.description
											}
											serializers={richText}
										/>
									)}
								</figcaption>
							)}
						</figure>
					)}
					<div className="col c1--lg show--lg pt5--md pt10--lg" />
					{inpirationSection?.description && (
						<div className="col c6--lg pt5 pt10--lg">
							<div className=" sans--18 sans--24--lg line-break rich-text ">
								<BlockContent
									blocks={inpirationSection.description}
									serializers={richText}
								/>
							</div>
						</div>
					)}
				</div>
			</div>

			{!isEmpty(inspirationQuote) && <PullQuote {...inspirationQuote} />}
		</Reveal>
	);

	const inspirationGallerySection = inspirationGallery &&
		inspirationGallery.length > 0 && (
			<Reveal>
				<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg grid-container contained">
					<div className="row">
						{inspirationGallery.map((slide, index) => {
							const { _key, title, description, artwork } = slide;
							return (
								<figure
									key={_key}
									className={cx('col c6--md', {
										mt5: index > 0,
										'mt0--md': index === 1,
										'mt10--md mt20--lg': index > 1,
									})}
								>
									<picture>
										<source
											srcSet={`${artwork.url}?w=1200&auto=format&q=100`}
											media="(min-width: 1000px)"
										/>
										<source
											srcSet={`${artwork.url}?w=800&auto=format`}
											media="(min-width: 600px)"
										/>
										<img
											alt={
												title ||
												'Library Street Collective'
											}
											className="db b--black pointer"
											onClick={() => {
												setFeaturedImage(slide);
												setTimeout(() => {
													setLightBoxOpen(true);
												}, 100);
											}}
											src={`${artwork.url}?w=600&auto=format`}
										/>
									</picture>

									{title && (
										<figcaption className="mt2 sans--14 sans--18--md">
											{title}
										</figcaption>
									)}
									{description && (
										<div className="mt2 sans--14 sans--18--md rich-text line-break">
											<BlockContent
												blocks={description}
												serializers={richText}
											/>
										</div>
									)}
								</figure>
							);
						})}
					</div>
				</div>
			</Reveal>
		);

	const artworkContentSection = artworkSections &&
		artworkSections.length > 0 && (
			<Reveal>
				<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg">
					<p className="sans--24 sans--36--md sans--48--lg grid-container contained mb5 mb10--md">
						{artworksTitle || 'Artwork'}
					</p>

					{!isEmpty(artworkSectionDescription) && (
						<div className="mt5 mt10--md mb5 mb10--md mb20--lg grid-container contained">
							<div className="row align--center">
								<div className="col c10--md c9--lg c7--xl">
									<div className="sans--18 sans--24--lg line-break rich-text ">
										<BlockContent
											blocks={artworkSectionDescription}
											serializers={richText}
										/>
									</div>
								</div>
							</div>
						</div>
					)}

					{artworkSectionVideo && (
						<VideoPlayer {...artworkSectionVideo} />
					)}

					{artworkSections && artworkSections.length > 0 && (
						<Fragment>
							{artworkSections.map((section, index) => {
								const { _key, content, cover, title } = section;

								return (
									<Reveal key={_key}>
										<section
											className={
												'reveal__slide reveal__delay--1  mb5 mb10--md mt5 mt10--md mb20--lg '
											}
										>
											{cover && (
												<div className="mb5">
													<picture>
														<source
															srcSet={`${cover.url}?w=2000&auto=format&q=100`}
															media="(min-width: 1200px)"
														/>
														<source
															srcSet={`${cover.url}?w=1200&auto=format&q=100`}
															media="(min-width: 1000px)"
														/>
														<source
															srcSet={`${cover.url}?w=800&auto=format`}
															media="(min-width: 600px)"
														/>
														<img
															alt={
																title ||
																'Library Street Collective'
															}
															className="db x bt--black bb--black"
															src={`${cover.url}?w=600&auto=format`}
														/>
													</picture>
													{cover.credit && (
														<p className="sans--14 sans--18--md mt1">
															{cover.credit}
														</p>
													)}
												</div>
											)}
											<p className="sans--24 sans--36--md grid-container contained">
												{title}
											</p>
											<div className="mt2">
												<ComponentList
													components={content}
												/>
											</div>
										</section>
									</Reveal>
								);
							})}
						</Fragment>
					)}
				</div>
			</Reveal>
		);

	const conversationsSection = (!isEmpty(conversationSections) ||
		!isEmpty(conversationsCover)) && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg">
				<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md grid-container contained">
					{conversationsTitle || 'Conversations'}
				</p>

				{!isEmpty(conversationsCover) && (
					<picture>
						<source
							srcSet={`${conversationsCover.url}?w=2000&auto=format&q=100`}
							media="(min-width: 1200px)"
						/>
						<source
							srcSet={`${conversationsCover.url}?w=1200&auto=format&q=100`}
							media="(min-width: 1000px)"
						/>
						<source
							srcSet={`${conversationsCover.url}?w=800&auto=format`}
							media="(min-width: 600px)"
						/>
						<img
							alt={`Conversations: ${title}`}
							className="db x bt--black bb--black"
							src={`${conversationsCover.url}?w=600&auto=format`}
						/>
					</picture>
				)}
				{conversationSections && conversationSections.length > 0 && (
					<Fragment>
						{conversationSections.map((section, index) => {
							const {
								_key,
								name,
								image,
								description,
								content = [],
							} = section;

							return (
								<div
									key={_key}
									className={cx(
										'mt5 mb5 mt10--md mb10--md grid-container contained',
										{
											'mt20--lg mb20--lg': index > 0,
										}
									)}
								>
									<p className="sans--18 sans--24--md sans--36--lg">
										{name}
									</p>

									<div className="row mt2">
										<figure className="col c5--lg psy--lg top pt10--lg">
											<picture>
												<source
													srcSet={`${image.url}?w=2000&auto=format&q=100`}
													media="(min-width: 1200px)"
												/>
												<source
													srcSet={`${image.url}?w=1200&auto=format&q=100`}
													media="(min-width: 1000px)"
												/>
												<source
													srcSet={`${image.url}?w=800&auto=format`}
													media="(min-width: 600px)"
												/>
												<img
													alt={
														name ||
														'Library Street Collective'
													}
													className="db x b--black"
													src={`${image.url}?w=600&auto=format`}
												/>
											</picture>
											<figcaption className="mt2 sans--12 sans--14--md">
												{name}
											</figcaption>
										</figure>
										<div className="col c1--lg show--lg pt5--md pt10--lg" />
										<div className="col c6--lg pt5 pt10--lg">
											<div className=" sans--18 sans--24--lg line-break rich-text ">
												<BlockContent
													blocks={description}
													serializers={richText}
												/>
											</div>
										</div>
									</div>

									<ComponentList components={content} />
								</div>
							);
						})}
					</Fragment>
				)}
			</div>
		</Reveal>
	);

	const studioSection = studioVisit && studioVisit.length > 0 && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg">
				<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md grid-container contained">
					Studio Visit
				</p>

				<ComponentList components={studioVisit} />
			</div>
		</Reveal>
	);

	const mailtoLink =
		inquire &&
		`mailto:info@lscgallery.com?subject=${inquire?.subject}&body=${
			!!inquire.body ? inquire.body : ''
		}`;

	const inquireSection = inquire && (
		<div
			id="artistContact"
			className="mt5 mb5 mt10--md mb10--md mt20--lg mb20--lg grid-container contained"
		>
			<a href={mailtoLink} className=" tc x db tile--large">
				<span className="sans--24 sans--36--md sans--48--lg">
					{(inquire && inquire.linkText) ||
						`Inquire about available works by ${title}`}
				</span>
			</a>
		</div>
	);

	return (
		<Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			<NewsletterPopup type="Anatomy" title={title} {...footer} />
			{heroSection}
			{introSection}
			{backGroundSection}
			{inspirationCoverSection}
			{inspirationSection}
			{inspirationGallerySection}
			{artworkContentSection}
			{conversationsSection}
			{studioSection}
			{inquireSection}
		</Fragment>
	);
};

export default Anatomy;
